<template>
  <b-card-code title="Ürün Fiyatları">
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'fullName'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1"/>
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>
        <span v-else-if="props.column.field === 'durum'">
          <b-badge variant="light-success" v-if="props.row.durumu==true">Aktif</b-badge>
         <b-badge variant="light-danger" v-if="props.row.durumu==false">Pasif</b-badge>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select v-model="pageLength" :options="['10','25','50']" class="mx-1"
                           @input="(value)=>props.perPageChanged({currentPerPage:value})"/>
            <span class="text-nowrap "> {{ props.total }} adet ürün listeleniyor.  </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>
<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {VueGoodTable} from 'vue-good-table'
import store from "@/store";

export default {
  components: {
    BCardCode,
    VueGoodTable,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: 'Ürün Adı',
          field: 'urunAdi',
          filterOptions: {
            enabled: true,
            placeholder: 'Ara',
          },
        },
        {
          label: 'Başlangıç Tarihi',
          field: 'baslangicTarihi',
          filterOptions: {
            enabled: true,
            placeholder: 'Ara',
          },
        },
        {
          label: 'Liste Fiyatı',
          field: 'liste',
          filterOptions: {
            enabled: true,
            placeholder: 'Ara',
          },
        },
        {
          label: 'İskonto Oranı',
          field: 'iskonto',
          filterOptions: {
            enabled: true,
            placeholder: 'Ara',
          },
        },
        {
          label: 'Net Fiyat',
          field: 'net',
          filterOptions: {
            enabled: true,
            placeholder: 'Ara',
          },
        },
        {
          label: 'Durum',
          field: 'durum',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    veri: {
      get() {
        return store.getters['loginStoreModule/getUserBilgileri'];
      },
    }
  },
  mounted() {
    this.$http.get("MusteriFiyatlariV1/" + this.veri.guid).then((i) => {
      this.rows = i.data;
    });
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
